export default {
  hireFormRequested12HoursAgo: 'Demandé il y a 12 heures',
  hireFormRequested24HoursAgo: 'Demandé il y a 24 heures',
  hireFormRequested48HoursAgo: 'Demandé il y a 48 heures',
  hireFormRequested72HoursAgo: 'Demandé il y a 72 heures',
  hireFormCompleted24HoursAgo: 'Completed 24 hrs ago',
  hireFormHelpRequested: 'Aide demandée',
  hireFormInReview: 'Resoumission demandée',

  hireFormResubmission12HoursAgo: `Resoumission pour formulaire d'embauche demandé il y a 12 heures`,
  hireFormResubmission24HoursAgo: `Resoumission pour formulaire d'embauche demandé il y a 24 heures`,
  hireFormResubmission48HoursAgo: `Resoumission pour formulaire d'embauche demandé il y a 48 heures`,
  hireFormResubmission72HoursAgo: `Resoumission pour formulaire d'embauche demandé il y a 72 heures`,

  bgCheckRequested24HoursAgo: 'Demandée il y a 24 heures',
  bgCheckRequested48HoursAgo: 'Demandée il y a 48 heures',
  bgCheckRequested72HoursAgo: 'Demandée il y a 72 heures',
  bgCheckFlaggedByCertnCandidates: 'Signalé par vérification des antécédents',
  bgCheckNotProcessedByCertn24HoursAgoCandidates: 'Pas encore traité ',

  offerMade12HoursAgo: 'Offre faite il y a 12 heures',
  offerMade24HoursAgo: 'Offre faite il y a 24 heures',
  offerMade36HoursAgo: 'Offre faite il y a 36 heures',
  offerMade48HoursAgo: 'Offre faite il y a 48 heures',

  summary: 'Sommaire',
  hiresProcessing: 'Embauches en cours',
  outstandingOffers: 'Offres en attente',
  toDo: 'À faire',
  completed: 'Complété',
  hireForm: "Formulaire d'embauche",
  backgroundCheck: 'Vérification des antécédents',
  offer: 'Offre',
  languageEvaluation: 'Évaluation de la langue',
  conversationalAI: 'IA conversationnel',

  pageTitle: 'Liste des tâches du jour',

  actionToTake: 'À faire',
  lastStatus: 'Dernière mise à jour',
  status: 'Statut',
  locationAndPosition: 'Emplacement & Poste',
  candidate: 'Candidat',
  hrPlus: 'h +',
  noData: 'Aucune donnée trouvée',
  hi: 'Salut',
  cancel: 'Annuler',
  sendEmail: 'Envoyer le courriel',
  send: 'Envoyer',
  sendAndDismissed: 'Envoyer et rejeter le candidat',
  sending: 'En train d’envoyer',
  contactCertn: 'Contacter Certn',
  contactDollarama: 'Contacter Dollarama',
  dismissedCandidateTitle: 'Candidat rejeté',
  offerTitle: 'Offre en attente',
  offerMade12HoursAgoDesp:
    'Offre faite il y a plus de 12 heures. Contacter le candidat pour l’informer qu’il doit répondre à l’offre.',
  offerMade24HoursAgoDesp:
    'Offre faite il y a plus de 24 heures. Contacter le candidat pour l’informer qu’il doit répondre à l’offre. Averti-le qu’il sera rejeté s’il ne répond pas à l’offre.',
  offerMade36HoursAgoDesp:
    'Offre faite il y a plus de 36 heures. Contacter le candidat pour l’informer qu’il doit répondre à l’offre. Averti-le qu’il sera rejeté s’il ne répond pas à l’offre.',
  offerMade48HoursAgoDesp:
    'Offre faite il y a plus de 48 heures. Dernière tentative: appeler le candidat par téléphone, et envoi-lui un message pour lui dire qu’il sera rejeté ce soir en moins qu’il accepte l’offre.',
  hireFormTitle: 'Formulaire d’embauche incomplet',
  hireFormResubmissionTitle: 'Pending Hire Form Resubmission Completion',
  hireFormRequested12HoursAgoDesp:
    'Formulaire d’embauche demandé il y a plus de 12 heures. Contacter le candidat pour lui demander de compléter le formulaire d’embauche.',
  hireFormRequested24HoursAgoDesp:
    'Formulaire d’embauche demandé il y a plus de 24 heures. Contacter le candidat pour lui demander de compléter le formulaire d’embauche.',
  hireFormRequested48HoursAgoDesp: `Formulaire d’embauche demandé il y a plus de 48 heures. Dernière tentative: contacter le candidat par téléphone, et envoi-lui un message pour lui dire qu'il sera rejeté si le formulaire n’est pas complété dans les 12 heures prochaines.`,
  hireFormRequested60HoursAgoDesp:
    'Formulaire d’embauche demandé il y a plus de 60 heures. Rejeter le candidat en moins qu’une justification suffisante est donnée. Réviser au quotidien et rejeter le candidat si approprié.',
  hireFormRequested72HoursAgoDesp:
    'Inform the candidate that they are being removed from the process due to not completing their hire form, requested over 72 hours ago.',

  hireFormResubmission12HoursAgoDesp:
    'Hire form resubmission over 12 hours ago. Message candidate, asking them to complete hire form as the last step in the hiring process.',
  hireFormResubmission24HoursAgoDesp: 'Hire form resubmission over 24 hours ago. Message candidate, to complete hire form.',
  hireFormResubmission48HoursAgoDesp:
    'Hire form resubmission over 48 hours ago. Final attempt: Call candidate by phone, then message them so they are aware that they will be removed from the process if this is not completed within 12 hours .',
  hireFormResubmission60HoursAgoDesp:
    'Hire form resubmission over 60 hours ago. Dismiss candidate unless sufficient justification for the delay has been given. Review daily and dismiss if appropriate.',
  hireFormResubmission72HoursAgoDesp:
    'Hire form resubmission over 72 hours ago. Dismiss candidate unless sufficient justification for the delay has been given. Review daily and dismiss if appropriate.',

  backgroundCheckTitle: 'Vérification des antécédents incomplète',
  bgCheckRequested12HoursAgoDesp:
    'Vérification des antécédents demandée il y a plus de 12 heures. Contacter le candidat pour lui demander de compléter la vérification.',
  bgCheckRequested24HoursAgoDesp:
    'Vérification des antécédents demandée il y a plus de 24 heures. Avertir le candidat qu’il sera rejeté s’il ne complète pas la vérification.',
  bgCheckRequested36HoursAgoDesp:
    'Vérification des antécédents demandée il y a plus de 36 heures. Contacter le candidat pour l’informer qu’il sera rejeté. Rejeter le candidat.',
  bgCheckFlaggedByCertnCandidatesDesp:
    'Vérification des antédédents marqué par le fournisseur. Analyser les résultats du rapport.',
  bgCheckRequested48HoursAgoDesp:
    'Vérification des antécédents demandée il y a plus de 48 heures. Avertir le candidat qu’il sera rejeté s’il ne complète pas la vérification.',
  bgCheckRequested72HoursAgoDesp:
    'Vérification des antécédents demandée il y a plus de 72 heures. Avertir le candidat qu’il sera rejeté s’il ne complète pas la vérification.',
  bgCheckNotProcessedByCertn24HoursAgoCandidatesDesp: `Vérification des antécédents toujours en analyse par Certn après 24 heures. Contacter Certn à <a href="mailto:support@certn.co" target='_blank'>support@certn.co</a> pour savoir l’état de la vérification.`,
  hireFormCompleted24HoursAgoDesp: `Contacter payroll@dollarama.com et mettre appyhere@dollarama.com en CC pour recevoir une mise à jour sur le formulaire d’embauche complété et en attente de vérification. Important: Contacter seulement si la date de début du candidat est planifiée pour cette semaine.`,
  hireForHelpRequestTitle: "Demande d'aide pour formulaire d'embauche",
  hireFormHelpRequestDesp:
    "Le candidat a demandé de l'aide pour compléter son formulaire d'embauche. Communiquer avec le candidat pour investiguer leur problème.",
  helpRequestFor: "Candidat pris à l'étape {{feildName}}",
  yourReply: 'Votre réponse',
  sendMessage: 'Envoyer message',
  enterYourMessage: 'Entrer votre message',
  showingResult: `Affiche présentement {{currentItemsLength}} d'un total de {{totalItemsLength}} tâches`,
  urlCopiedInfo: 'URL du candidat copié',
  showCompletedItems: 'Afficher les tâches complétées',
};
