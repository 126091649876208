import React, { MouseEvent } from 'react';
import { Box, Image, Modal, ModalOverlay, ModalContent, ModalBody, useDisclosure, IconButton } from '@chakra-ui/core';
import { FiDownload } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import colors from '../../../../../../styles/colors';

export enum SupportedFileTypes {
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PDF = 'application/pdf',
  PNG = 'image/png',
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  DOC = 'application/msword',
}

const FileTypeToExtension: { [key in SupportedFileTypes]: string } = {
  [SupportedFileTypes.PDF]: '.pdf',
  [SupportedFileTypes.DOC]: '.doc',
  [SupportedFileTypes.DOCX]: '.docx',
  [SupportedFileTypes.JPG]: '.jpg',
  [SupportedFileTypes.JPEG]: '.jpeg',
  [SupportedFileTypes.PNG]: '.png',
};

interface CandidateResumeProps {
  url: string;
  type: SupportedFileTypes;
}

const CandidateResume: React.FC<CandidateResumeProps> = ({ url, type }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const renderPreview = () => {
    switch (type) {
      case SupportedFileTypes.PDF:
        return <embed src={url} type="application/pdf" width="100%" height="200px" style={{ borderRadius: '8px' }} />;
      case SupportedFileTypes.DOC:
      case SupportedFileTypes.DOCX:
        return (
          <iframe
            src={`https://docs.google.com/gview?url=${encodeURIComponent(url)}&embedded=true&view=preview`}
            style={{ width: '100%', height: '200px', border: 'none' }}
            title="Document Preview"
            data-testid="docPreview"
          />
        );
      case SupportedFileTypes.JPG:
      case SupportedFileTypes.JPEG:
      case SupportedFileTypes.PNG:
        return <Image src={url} alt="Image Preview" width="100%" height="200px" borderRadius="8px" objectFit="cover" />;
      default:
        return (
          <Box
            width="100%"
            height="200px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor="#f2f2f2"
            borderRadius="8px"
            fontSize="14px"
            color="#666"
          >
            {t('profile.noResumePreviewAvailable')}
          </Box>
        );
    }
  };

  const renderModalContent = () => {
    switch (type) {
      case SupportedFileTypes.PDF:
        return <embed src={url} type="application/pdf" width="100%" height="700px" />;
      case SupportedFileTypes.DOC:
      case SupportedFileTypes.DOCX:
        return (
          <iframe
            src={`https://docs.google.com/gview?url=${encodeURIComponent(url)}&embedded=true`}
            style={{ width: '100%', height: '700px', border: 'none' }}
            title="Document Preview"
            data-testid="docView"
          />
        );
      case SupportedFileTypes.JPG:
      case SupportedFileTypes.JPEG:
      case SupportedFileTypes.PNG:
        return <Image src={url} alt="Image Preview" maxWidth="80vw" height="auto" />;
      default:
        return <Box>No Preview Available</Box>;
    }
  };

  const handleDownload = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (
      type === SupportedFileTypes.PDF ||
      type === SupportedFileTypes.PNG ||
      type === SupportedFileTypes.JPG ||
      type === SupportedFileTypes.JPEG
    ) {
      const fileName = url.split('/').pop();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          const alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = `${fileName}${FileTypeToExtension[type]}`;
          alink.click();
        });
    } else {
      const link = document.createElement('a');
      link.href = url;
      link.download = url.split('/').pop() || 'download';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Box
      margin="20px"
      marginTop="10px"
      border="1px solid #ddd"
      borderRadius="8px"
      textAlign="center"
      backgroundColor="#fff"
      cursor="pointer"
    >
      <Box position="relative">
        {renderPreview()}

        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          borderRight="20px solid #fff"
          borderRadius="10px"
          backgroundColor="rgba(0, 0, 0, 0)"
          data-testid="previewWrapper"
          onClick={onOpen}
        />

        <IconButton
          onClick={handleDownload}
          mr={3}
          background="white"
          variant="outline"
          aria-label="First"
          borderRadius="4px"
          h="28px"
          w="28px"
          minW="28px"
          borderColor="#718096"
          icon={<FiDownload color={colors.blue[400]} strokeWidth="3px" />}
          data-testid="downloadBtn"
          position="absolute"
          top="10px"
          right="10px"
          size="sm"
          border={`2px solid ${colors.blue[400]} !important`}
          cursor="pointer"
        />
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent
          maxW={
            type === SupportedFileTypes.PNG || type === SupportedFileTypes.JPG || type === SupportedFileTypes.JPEG
              ? 'max-content'
              : '80vw'
          }
          maxH="max-content"
        >
          <ModalBody padding={0} overflowY="auto">
            {renderModalContent()}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CandidateResume;
