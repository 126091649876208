export default {
  createBusiness: {
    businessCreation: 'Location Creation',
    businessCreationDetail: 'Search for your business location by name. Location closer to you will appear first.',
    businessName: 'Location Name',
    businessId: 'Location Id',
    address: 'Address',
    city: 'City',
    state: 'State / Province / Region',
    zip: 'ZIP / Postal Code',
    country: 'Country',
    canada: 'Canada',
    usa: 'USA',
    url: 'URL',
    phone: 'Phone Number',
    create: 'Create',
    businessNameRequired: 'Business Location name is required',
    cityNameRequired: 'City name is required',
    addressRequired: 'Business Address is required',
    countryRequired: 'Country is required',
    zipRequired: 'Zip or Postal code is required',
    webSiteRequired: 'Website is required',
    websiteInvalid: 'The website must have a valid URL',
    stateRequired: 'State or Province or Region is required',
    phoneRequired: 'Phone Number is required',
    phoneMin: 'Phone Number must be valid',
    latitudeRequired: 'Latitude is required',
    longitudeRequired: 'Longitude is required',
    placeIdRequired: 'Place Id  is required',
    positionRequired: 'Position  is required',
    viewBusinessList: 'View Location List',
    update: 'Update',
    successMsg: 'Business location is created successfully',
    successMsgBusinessUpdate: 'Business location updated successfully',
    businessAlreadyExists: 'There is already a business set with this location in AppyHere',
    group: 'Group',
  },
  businessList: {
    myLocations: `My Locations`,
    allLocations: `All Locations`,
    searchBusiness: 'Search by location name or location ID',
    business: 'Locations',
    create: 'Create',
    listOfBusiness: 'List of Locations',
    loadMore: ' Load More',
    job: 'Position',
    jobs: 'Positions',
    openPositions: 'Open Position',
    noBusinessCreated: 'First add a business location to create jobs and see candidates',
  },
  topCandidate: {
    filters: 'Filters',
    appliedFilters: 'Applied Filters',
    resetFilters: 'Reset Filters',
    salary: 'Salary',
    withInRange: 'Within Position Salary Range',
    aboveRange: 'Above Position Salary Range',
    salaryRange: 'Salary Range (Hourly Rate)',
    top: 'Top',
    bookmarked: 'Bookmarked',
    active: 'Active',
    searchCandidates: 'Search Candidates',
    minExperience: 'Minimum Experience',
    years: 'Years',
    lastActive: 'Last Active',
    reset: 'Reset',
    lessThanAWeek: 'less than a week',
    oneMonth: ' 1 month',
    sixMonths: '6 months',
    lessThanSixMonth: 'less than 6 months',
    oneYear: '1 year',
    sixYears: '6 years',

    contactCandidate: `Send Contact Request?`,
    contactNote: `Sending a contact request will show the candidate your interest in their profile. They will be able to see the position and apply. You will only be able to see their full profile if they prequalify for the position.`,
    moreThan10years: 'More than 10 years of direct experience',
    experience: `{{years}} years of direct experience`,
    addNotes: 'Add additional information for the candidate',
    cancel: 'Cancel',
    sendContactRequest: 'Send Contact Request',

    hasApplied: 'Has Applied',
    dismissCandidate: 'Dismiss Candidate',
    communicationCandidate: 'Message This Candidate',

    engageCandidateModal: {
      header: 'You are using tokens for this account',
      bodyText: '{{name}} seems to be a good candidate?',
      useToken: 'Use 1 token to Call & Meet',
      engage: 'Engage',
    },
    engageBtnLabel: 'Engage Candidate',
    engageDisableMsg: 'Seeker has not accepted your contact request yet!',
    noBookmarkedCandidates: 'You have no bookmarked candidates.',
    noTopCandidates: 'There are no top candidates for this position.',
  },
};
